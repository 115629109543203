<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 ghd-base-color-black"
        >
          <div
            class="d-flex align-center justify-center h-full"
          >
            <!-- triangle bg -->
            <img
              height="auto"
              width="auto"
              :src="appLogo"
            />
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center pa-10 pb-0 ghd-base-color-white"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card
                flat
                class="ghd-base-color-white ghd-border-black"
              >
                <v-card-text>
                  <p class="text-large text-uppercase text-black text-bolder ghd-ITCAvant">
                    Assistenza ghd
                  </p>
                  <p class="mb-2">
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-alert
                    v-if="noFormErrorMessage.length"
                    type="error"
                  >
                    {{ noFormErrorMessage }}
                  </v-alert>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class=""
                      >
                        Password dimenticata?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    Non hai un account?
                  </p>
                  <router-link :to="{name:'home'}">
                    Crea un account
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text>

                <!-- socail links -->
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    iconav
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      {{ noFormErrorMessage }}
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiYoutube, mdiInstagram, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from 'axios'
import Vue from 'vue'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { cryptDataIntoLocalStorage } from '@core/utils/crypt'
import store from '@/store'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const axiosIns = computed(() => axios.create({
      // You can add your headers here
      // ================================
      baseURL: `${process.env.VUE_APP_APIURL}`,

      // withCredentials: true,

      // timeout: 1000,
      // headers: { 'Access-Control-Allow-Origin:': '*' },
    }))
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiInstagram,
        color: '#E1306C',
        colorInDark: '#E1306C',
      },
      {
        icon: mdiYoutube,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const noFormErrorMessage = computed(() => store.getters['global/getErrorMessages'])

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      axiosIns.value.get('/sanctum/csrf-cookie').then(() => {
        axiosIns.value.post('api/login', { email: email.value, password: password.value }).then(response => {
          const { accessToken } = response.data.data.meta

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          const { user } = response.data.data

          // Array dei permessi definitvo da salvare nei permessi
          const permissions = []

          const ability = response.data.data.meta.permissions

          ability.forEach(permission => {
            const permissionSplitted = permission.split('_')
            permissions.push({ action: permissionSplitted[0], subject: permissionSplitted[1] })
          })

          permissions.push({ action: 'read', subject: 'Public' })

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(permissions)

          // Set user's ability in localStorage for Access Control
          cryptDataIntoLocalStorage('userAbility', JSON.stringify(permissions))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          // delete user.ability

          // Set user's data in localStorage for UI/Other purpose
          cryptDataIntoLocalStorage('userData', JSON.stringify(user))

          // On success redirect to home
          router.push('/dashboard')
        }).catch(error => {
          if (error.response.status === 401 || error.response.status === 500 || error.response.status === 404) {
            Vue.$toast(error.response.data.message, {
              timeout: 3000,
            })
          }

          if (error.response.status === 403) {
            axios.post('api/emails/verification-code', { email: email.value }).then(() => {
              localStorage.setItem('email_verification_account', email.value)
              router.push('/verification')
            }).catch()
          }
        }) // credentials didn't match
      })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      noFormErrorMessage,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInstagram,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
